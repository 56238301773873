const CountryMap = {
    AD: { CountryCode: "AD", CountryName: "Andorra", ContinentCode: "EU", ContinentName: "Europe" },
    AE: { CountryCode: "AE", CountryName: "United Arab Emirates", ContinentCode: "AS", ContinentName: "Asia" },
    AF: { CountryCode: "AF", CountryName: "Afghanistan", ContinentCode: "AS", ContinentName: "Asia" },
    AG: { CountryCode: "AG", CountryName: "Antigua and Barbuda", ContinentCode: "NA", ContinentName: "North America" },
    AI: { CountryCode: "AI", CountryName: "Anguilla", ContinentCode: "NA", ContinentName: "North America" },
    AL: { CountryCode: "AL", CountryName: "Albania", ContinentCode: "EU", ContinentName: "Europe" },
    AM: { CountryCode: "AM", CountryName: "Armenia", ContinentCode: "EU", ContinentName: "Europe" },
    AO: { CountryCode: "AO", CountryName: "Angola", ContinentCode: "AF", ContinentName: "Africa" },
    AQ: { CountryCode: "AQ", CountryName: "Antarctica", ContinentCode: "AN", ContinentName: "Antarctica" },
    AR: { CountryCode: "AR", CountryName: "Argentina", ContinentCode: "SA", ContinentName: "South America" },
    AS: { CountryCode: "AS", CountryName: "American Samoa", ContinentCode: "OC", ContinentName: "Oceania" },
    AT: { CountryCode: "AT", CountryName: "Austria", ContinentCode: "EU", ContinentName: "Europe" },
    AU: { CountryCode: "AU", CountryName: "Australia", ContinentCode: "OC", ContinentName: "Oceania" },
    AW: { CountryCode: "AW", CountryName: "Aruba", ContinentCode: "NA", ContinentName: "North America" },
    AX: { CountryCode: "AX", CountryName: "Åland Islands", ContinentCode: "EU", ContinentName: "Europe" },
    AZ: { CountryCode: "AZ", CountryName: "Azerbaijan", ContinentCode: "AS", ContinentName: "Asia" },
    BA: { CountryCode: "BA", CountryName: "Bosnia and Herzegovina", ContinentCode: "EU", ContinentName: "Europe" },
    BB: { CountryCode: "BB", CountryName: "Barbados", ContinentCode: "NA", ContinentName: "North America" },
    BD: { CountryCode: "BD", CountryName: "Bangladesh", ContinentCode: "AS", ContinentName: "Asia" },
    BE: { CountryCode: "BE", CountryName: "Belgium", ContinentCode: "EU", ContinentName: "Europe" },
    BF: { CountryCode: "BF", CountryName: "Burkina Faso", ContinentCode: "AF", ContinentName: "Africa" },
    BG: { CountryCode: "BG", CountryName: "Bulgaria", ContinentCode: "EU", ContinentName: "Europe" },
    BH: { CountryCode: "BH", CountryName: "Bahrain", ContinentCode: "AS", ContinentName: "Asia" },
    BI: { CountryCode: "BI", CountryName: "Burundi", ContinentCode: "AF", ContinentName: "Africa" },
    BJ: { CountryCode: "BJ", CountryName: "Benin", ContinentCode: "AF", ContinentName: "Africa" },
    BL: { CountryCode: "BL", CountryName: "Saint Barthélemy", ContinentCode: "NA", ContinentName: "North America" },
    BM: { CountryCode: "BM", CountryName: "Bermuda", ContinentCode: "NA", ContinentName: "North America" },
    BN: { CountryCode: "BN", CountryName: "Brunei Darussalam", ContinentCode: "AS", ContinentName: "Asia" },
    BO: { CountryCode: "BO", CountryName: "Bolivia (Plurinational State of)", ContinentCode: "SA", ContinentName: "South America" },
    BQ: { CountryCode: "BQ", CountryName: "Bonaire, Sint Eustatius and Saba", ContinentCode: "NA", ContinentName: "North America" },
    BR: { CountryCode: "BR", CountryName: "Brazil", ContinentCode: "SA", ContinentName: "South America" },
    BS: { CountryCode: "BS", CountryName: "Bahamas", ContinentCode: "NA", ContinentName: "North America" },
    BT: { CountryCode: "BT", CountryName: "Bhutan", ContinentCode: "AS", ContinentName: "Asia" },
    BV: { CountryCode: "BV", CountryName: "Bouvet Island", ContinentCode: "AN", ContinentName: "Antarctica" },
    BW: { CountryCode: "BW", CountryName: "Botswana", ContinentCode: "AF", ContinentName: "Africa" },
    BY: { CountryCode: "BY", CountryName: "Belarus", ContinentCode: "EU", ContinentName: "Europe" },
    BZ: { CountryCode: "BZ", CountryName: "Belize", ContinentCode: "NA", ContinentName: "North America" },
    CA: { CountryCode: "CA", CountryName: "Canada", ContinentCode: "NA", ContinentName: "North America" },
    CC: { CountryCode: "CC", CountryName: "Cocos (Keeling) Islands", ContinentCode: "AS", ContinentName: "Asia" },
    CD: { CountryCode: "CD", CountryName: "Congo, Democratic Republic of the", ContinentCode: "AF", ContinentName: "Africa" },
    CF: { CountryCode: "CF", CountryName: "Central African Republic", ContinentCode: "AF", ContinentName: "Africa" },
    CG: { CountryCode: "CG", CountryName: "Congo", ContinentCode: "AF", ContinentName: "Africa" },
    CH: { CountryCode: "CH", CountryName: "Switzerland", ContinentCode: "EU", ContinentName: "Europe" },
    CI: { CountryCode: "CI", CountryName: "Côte d'Ivoire", ContinentCode: "AF", ContinentName: "Africa" },
    CK: { CountryCode: "CK", CountryName: "Cook Islands", ContinentCode: "OC", ContinentName: "Oceania" },
    CL: { CountryCode: "CL", CountryName: "Chile", ContinentCode: "SA", ContinentName: "South America" },
    CM: { CountryCode: "CM", CountryName: "Cameroon", ContinentCode: "AF", ContinentName: "Africa" },
    CN: { CountryCode: "CN", CountryName: "China", ContinentCode: "AS", ContinentName: "Asia" },
    CO: { CountryCode: "CO", CountryName: "Colombia", ContinentCode: "SA", ContinentName: "South America" },
    CR: { CountryCode: "CR", CountryName: "Costa Rica", ContinentCode: "NA", ContinentName: "North America" },
    CU: { CountryCode: "CU", CountryName: "Cuba", ContinentCode: "NA", ContinentName: "North America" },
    CV: { CountryCode: "CV", CountryName: "Cabo Verde", ContinentCode: "AF", ContinentName: "Africa" },
    CW: { CountryCode: "CW", CountryName: "Curaçao", ContinentCode: "NA", ContinentName: "North America" },
    CX: { CountryCode: "CX", CountryName: "Christmas Island", ContinentCode: "AS", ContinentName: "Asia" },
    CY: { CountryCode: "CY", CountryName: "Cyprus", ContinentCode: "EU", ContinentName: "Europe" },
    CZ: { CountryCode: "CZ", CountryName: "Czechia", ContinentCode: "EU", ContinentName: "Europe" },
    DE: { CountryCode: "DE", CountryName: "Germany", ContinentCode: "EU", ContinentName: "Europe" },
    DJ: { CountryCode: "DJ", CountryName: "Djibouti", ContinentCode: "AF", ContinentName: "Africa" },
    DK: { CountryCode: "DK", CountryName: "Denmark", ContinentCode: "EU", ContinentName: "Europe" },
    DM: { CountryCode: "DM", CountryName: "Dominica", ContinentCode: "NA", ContinentName: "North America" },
    DO: { CountryCode: "DO", CountryName: "Dominican Republic", ContinentCode: "NA", ContinentName: "North America" },
    DZ: { CountryCode: "DZ", CountryName: "Algeria", ContinentCode: "AF", ContinentName: "Africa" },
    EC: { CountryCode: "EC", CountryName: "Ecuador", ContinentCode: "SA", ContinentName: "South America" },
    EE: { CountryCode: "EE", CountryName: "Estonia", ContinentCode: "EU", ContinentName: "Europe" },
    EG: { CountryCode: "EG", CountryName: "Egypt", ContinentCode: "AF", ContinentName: "Africa" },
    EH: { CountryCode: "EH", CountryName: "Western Sahara", ContinentCode: "AF", ContinentName: "Africa" },
    ER: { CountryCode: "ER", CountryName: "Eritrea", ContinentCode: "AF", ContinentName: "Africa" },
    ES: { CountryCode: "ES", CountryName: "Spain", ContinentCode: "EU", ContinentName: "Europe" },
    ET: { CountryCode: "ET", CountryName: "Ethiopia", ContinentCode: "AF", ContinentName: "Africa" },
    FI: { CountryCode: "FI", CountryName: "Finland", ContinentCode: "EU", ContinentName: "Europe" },
    FJ: { CountryCode: "FJ", CountryName: "Fiji", ContinentCode: "OC", ContinentName: "Oceania" },
    FK: { CountryCode: "FK", CountryName: "Falkland Islands (Malvinas)", ContinentCode: "SA", ContinentName: "South America" },
    FM: { CountryCode: "FM", CountryName: "Micronesia (Federated States of)", ContinentCode: "OC", ContinentName: "Oceania" },
    FO: { CountryCode: "FO", CountryName: "Faroe Islands", ContinentCode: "EU", ContinentName: "Europe" },
    FR: { CountryCode: "FR", CountryName: "France", ContinentCode: "EU", ContinentName: "Europe" },
    GA: { CountryCode: "GA", CountryName: "Gabon", ContinentCode: "AF", ContinentName: "Africa" },
    GB: { CountryCode: "GB", CountryName: "United Kingdom of Great Britain and Northern Ireland", ContinentCode: "EU", ContinentName: "Europe" },
    GD: { CountryCode: "GD", CountryName: "Grenada", ContinentCode: "NA", ContinentName: "North America" },
    GE: { CountryCode: "GE", CountryName: "Georgia", ContinentCode: "AS", ContinentName: "Asia" },
    GF: { CountryCode: "GF", CountryName: "French Guiana", ContinentCode: "SA", ContinentName: "South America" },
    GG: { CountryCode: "GG", CountryName: "Guernsey", ContinentCode: "EU", ContinentName: "Europe" },
    GH: { CountryCode: "GH", CountryName: "Ghana", ContinentCode: "AF", ContinentName: "Africa" },
    GI: { CountryCode: "GI", CountryName: "Gibraltar", ContinentCode: "EU", ContinentName: "Europe" },
    GL: { CountryCode: "GL", CountryName: "Greenland", ContinentCode: "NA", ContinentName: "North America" },
    GM: { CountryCode: "GM", CountryName: "Gambia", ContinentCode: "AF", ContinentName: "Africa" },
    GN: { CountryCode: "GN", CountryName: "Guinea", ContinentCode: "AF", ContinentName: "Africa" },
    GP: { CountryCode: "GP", CountryName: "Guadeloupe", ContinentCode: "NA", ContinentName: "North America" },
    GQ: { CountryCode: "GQ", CountryName: "Equatorial Guinea", ContinentCode: "AF", ContinentName: "Africa" },
    GR: { CountryCode: "GR", CountryName: "Greece", ContinentCode: "EU", ContinentName: "Europe" },
    GS: { CountryCode: "GS", CountryName: "South Georgia and the South Sandwich Islands", ContinentCode: "AN", ContinentName: "Antarctica" },
    GT: { CountryCode: "GT", CountryName: "Guatemala", ContinentCode: "NA", ContinentName: "North America" },
    GU: { CountryCode: "GU", CountryName: "Guam", ContinentCode: "OC", ContinentName: "Oceania" },
    GW: { CountryCode: "GW", CountryName: "Guinea-Bissau", ContinentCode: "AF", ContinentName: "Africa" },
    GY: { CountryCode: "GY", CountryName: "Guyana", ContinentCode: "SA", ContinentName: "South America" },
    HK: { CountryCode: "HK", CountryName: "Hong Kong", ContinentCode: "AS", ContinentName: "Asia" },
    HM: { CountryCode: "HM", CountryName: "Heard Island and McDonald Islands", ContinentCode: "AN", ContinentName: "Antarctica" },
    HN: { CountryCode: "HN", CountryName: "Honduras", ContinentCode: "NA", ContinentName: "North America" },
    HR: { CountryCode: "HR", CountryName: "Croatia", ContinentCode: "EU", ContinentName: "Europe" },
    HT: { CountryCode: "HT", CountryName: "Haiti", ContinentCode: "NA", ContinentName: "North America" },
    HU: { CountryCode: "HU", CountryName: "Hungary", ContinentCode: "EU", ContinentName: "Europe" },
    ID: { CountryCode: "ID", CountryName: "Indonesia", ContinentCode: "AS", ContinentName: "Asia" },
    IE: { CountryCode: "IE", CountryName: "Ireland", ContinentCode: "EU", ContinentName: "Europe" },
    IL: { CountryCode: "IL", CountryName: "Israel", ContinentCode: "AS", ContinentName: "Asia" },
    IM: { CountryCode: "IM", CountryName: "Isle of Man", ContinentCode: "EU", ContinentName: "Europe" },
    IN: { CountryCode: "IN", CountryName: "India", ContinentCode: "AS", ContinentName: "Asia" },
    IO: { CountryCode: "IO", CountryName: "British Indian Ocean Territory", ContinentCode: "AS", ContinentName: "Asia" },
    IQ: { CountryCode: "IQ", CountryName: "Iraq", ContinentCode: "AS", ContinentName: "Asia" },
    IR: { CountryCode: "IR", CountryName: "Iran (Islamic Republic of)", ContinentCode: "AS", ContinentName: "Asia" },
    IS: { CountryCode: "IS", CountryName: "Iceland", ContinentCode: "EU", ContinentName: "Europe" },
    IT: { CountryCode: "IT", CountryName: "Italy", ContinentCode: "EU", ContinentName: "Europe" },
    JE: { CountryCode: "JE", CountryName: "Jersey", ContinentCode: "EU", ContinentName: "Europe" },
    JM: { CountryCode: "JM", CountryName: "Jamaica", ContinentCode: "NA", ContinentName: "North America" },
    JO: { CountryCode: "JO", CountryName: "Jordan", ContinentCode: "AS", ContinentName: "Asia" },
    JP: { CountryCode: "JP", CountryName: "Japan", ContinentCode: "AS", ContinentName: "Asia" },
    KE: { CountryCode: "KE", CountryName: "Kenya", ContinentCode: "AF", ContinentName: "Africa" },
    KG: { CountryCode: "KG", CountryName: "Kyrgyzstan", ContinentCode: "AS", ContinentName: "Asia" },
    KH: { CountryCode: "KH", CountryName: "Cambodia", ContinentCode: "AS", ContinentName: "Asia" },
    KI: { CountryCode: "KI", CountryName: "Kiribati", ContinentCode: "OC", ContinentName: "Oceania" },
    KM: { CountryCode: "KM", CountryName: "Comoros", ContinentCode: "AF", ContinentName: "Africa" },
    KN: { CountryCode: "KN", CountryName: "Saint Kitts and Nevis", ContinentCode: "NA", ContinentName: "North America" },
    KP: { CountryCode: "KP", CountryName: "Korea (Democratic People's Republic of)", ContinentCode: "AS", ContinentName: "Asia" },
    KR: { CountryCode: "KR", CountryName: "Korea, Republic of", ContinentCode: "AS", ContinentName: "Asia" },
    KW: { CountryCode: "KW", CountryName: "Kuwait", ContinentCode: "AS", ContinentName: "Asia" },
    KY: { CountryCode: "KY", CountryName: "Cayman Islands", ContinentCode: "NA", ContinentName: "North America" },
    KZ: { CountryCode: "KZ", CountryName: "Kazakhstan", ContinentCode: "AS", ContinentName: "Asia" },
    LA: { CountryCode: "LA", CountryName: "Lao People's Democratic Republic", ContinentCode: "AS", ContinentName: "Asia" },
    LB: { CountryCode: "LB", CountryName: "Lebanon", ContinentCode: "AS", ContinentName: "Asia" },
    LC: { CountryCode: "LC", CountryName: "Saint Lucia", ContinentCode: "NA", ContinentName: "North America" },
    LI: { CountryCode: "LI", CountryName: "Liechtenstein", ContinentCode: "EU", ContinentName: "Europe" },
    LK: { CountryCode: "LK", CountryName: "Sri Lanka", ContinentCode: "AS", ContinentName: "Asia" },
    LR: { CountryCode: "LR", CountryName: "Liberia", ContinentCode: "AF", ContinentName: "Africa" },
    LS: { CountryCode: "LS", CountryName: "Lesotho", ContinentCode: "AF", ContinentName: "Africa" },
    LT: { CountryCode: "LT", CountryName: "Lithuania", ContinentCode: "EU", ContinentName: "Europe" },
    LU: { CountryCode: "LU", CountryName: "Luxembourg", ContinentCode: "EU", ContinentName: "Europe" },
    LV: { CountryCode: "LV", CountryName: "Latvia", ContinentCode: "EU", ContinentName: "Europe" },
    LY: { CountryCode: "LY", CountryName: "Libya", ContinentCode: "AF", ContinentName: "Africa" },
    MA: { CountryCode: "MA", CountryName: "Morocco", ContinentCode: "AF", ContinentName: "Africa" },
    MC: { CountryCode: "MC", CountryName: "Monaco", ContinentCode: "EU", ContinentName: "Europe" },
    MD: { CountryCode: "MD", CountryName: "Moldova, Republic of", ContinentCode: "EU", ContinentName: "Europe" },
    ME: { CountryCode: "ME", CountryName: "Montenegro", ContinentCode: "EU", ContinentName: "Europe" },
    MF: { CountryCode: "MF", CountryName: "Saint Martin (French part)", ContinentCode: "NA", ContinentName: "North America" },
    MG: { CountryCode: "MG", CountryName: "Madagascar", ContinentCode: "AF", ContinentName: "Africa" },
    MH: { CountryCode: "MH", CountryName: "Marshall Islands", ContinentCode: "OC", ContinentName: "Oceania" },
    MK: { CountryCode: "MK", CountryName: "North Macedonia", ContinentCode: "EU", ContinentName: "Europe" },
    ML: { CountryCode: "ML", CountryName: "Mali", ContinentCode: "AF", ContinentName: "Africa" },
    MM: { CountryCode: "MM", CountryName: "Myanmar", ContinentCode: "AS", ContinentName: "Asia" },
    MN: { CountryCode: "MN", CountryName: "Mongolia", ContinentCode: "AS", ContinentName: "Asia" },
    MO: { CountryCode: "MO", CountryName: "Macao", ContinentCode: "AS", ContinentName: "Asia" },
    MP: { CountryCode: "MP", CountryName: "Northern Mariana Islands", ContinentCode: "OC", ContinentName: "Oceania" },
    MQ: { CountryCode: "MQ", CountryName: "Martinique", ContinentCode: "NA", ContinentName: "North America" },
    MR: { CountryCode: "MR", CountryName: "Mauritania", ContinentCode: "AF", ContinentName: "Africa" },
    MS: { CountryCode: "MS", CountryName: "Montserrat", ContinentCode: "NA", ContinentName: "North America" },
    MT: { CountryCode: "MT", CountryName: "Malta", ContinentCode: "EU", ContinentName: "Europe" },
    MU: { CountryCode: "MU", CountryName: "Mauritius", ContinentCode: "AF", ContinentName: "Africa" },
    MV: { CountryCode: "MV", CountryName: "Maldives", ContinentCode: "AS", ContinentName: "Asia" },
    MW: { CountryCode: "MW", CountryName: "Malawi", ContinentCode: "AF", ContinentName: "Africa" },
    MX: { CountryCode: "MX", CountryName: "Mexico", ContinentCode: "NA", ContinentName: "North America" },
    MY: { CountryCode: "MY", CountryName: "Malaysia", ContinentCode: "AS", ContinentName: "Asia" },
    MZ: { CountryCode: "MZ", CountryName: "Mozambique", ContinentCode: "AF", ContinentName: "Africa" },
    NA: { CountryCode: "NA", CountryName: "Namibia", ContinentCode: "AF", ContinentName: "Africa" },
    NC: { CountryCode: "NC", CountryName: "New Caledonia", ContinentCode: "OC", ContinentName: "Oceania" },
    NE: { CountryCode: "NE", CountryName: "Niger", ContinentCode: "AF", ContinentName: "Africa" },
    NF: { CountryCode: "NF", CountryName: "Norfolk Island", ContinentCode: "OC", ContinentName: "Oceania" },
    NG: { CountryCode: "NG", CountryName: "Nigeria", ContinentCode: "AF", ContinentName: "Africa" },
    NI: { CountryCode: "NI", CountryName: "Nicaragua", ContinentCode: "NA", ContinentName: "North America" },
    NL: { CountryCode: "NL", CountryName: "Netherlands", ContinentCode: "EU", ContinentName: "Europe" },
    NO: { CountryCode: "NO", CountryName: "Norway", ContinentCode: "EU", ContinentName: "Europe" },
    NP: { CountryCode: "NP", CountryName: "Nepal", ContinentCode: "AS", ContinentName: "Asia" },
    NR: { CountryCode: "NR", CountryName: "Nauru", ContinentCode: "OC", ContinentName: "Oceania" },
    NU: { CountryCode: "NU", CountryName: "Niue", ContinentCode: "OC", ContinentName: "Oceania" },
    NZ: { CountryCode: "NZ", CountryName: "New Zealand", ContinentCode: "OC", ContinentName: "Oceania" },
    OM: { CountryCode: "OM", CountryName: "Oman", ContinentCode: "AS", ContinentName: "Asia" },
    PA: { CountryCode: "PA", CountryName: "Panama", ContinentCode: "NA", ContinentName: "North America" },
    PE: { CountryCode: "PE", CountryName: "Peru", ContinentCode: "SA", ContinentName: "South America" },
    PF: { CountryCode: "PF", CountryName: "French Polynesia", ContinentCode: "OC", ContinentName: "Oceania" },
    PG: { CountryCode: "PG", CountryName: "Papua New Guinea", ContinentCode: "OC", ContinentName: "Oceania" },
    PH: { CountryCode: "PH", CountryName: "Philippines", ContinentCode: "AS", ContinentName: "Asia" },
    PK: { CountryCode: "PK", CountryName: "Pakistan", ContinentCode: "AS", ContinentName: "Asia" },
    PL: { CountryCode: "PL", CountryName: "Poland", ContinentCode: "EU", ContinentName: "Europe" },
    PM: { CountryCode: "PM", CountryName: "Saint Pierre and Miquelon", ContinentCode: "NA", ContinentName: "North America" },
    PN: { CountryCode: "PN", CountryName: "Pitcairn", ContinentCode: "OC", ContinentName: "Oceania" },
    PR: { CountryCode: "PR", CountryName: "Puerto Rico", ContinentCode: "NA", ContinentName: "North America" },
    PS: { CountryCode: "PS", CountryName: "Palestine, State of", ContinentCode: "AS", ContinentName: "Asia" },
    PT: { CountryCode: "PT", CountryName: "Portugal", ContinentCode: "EU", ContinentName: "Europe" },
    PW: { CountryCode: "PW", CountryName: "Palau", ContinentCode: "OC", ContinentName: "Oceania" },
    PY: { CountryCode: "PY", CountryName: "Paraguay", ContinentCode: "SA", ContinentName: "South America" },
    QA: { CountryCode: "QA", CountryName: "Qatar", ContinentCode: "AS", ContinentName: "Asia" },
    RE: { CountryCode: "RE", CountryName: "Réunion", ContinentCode: "AF", ContinentName: "Africa" },
    RO: { CountryCode: "RO", CountryName: "Romania", ContinentCode: "EU", ContinentName: "Europe" },
    RS: { CountryCode: "RS", CountryName: "Serbia", ContinentCode: "EU", ContinentName: "Europe" },
    RU: { CountryCode: "RU", CountryName: "Russian Federation", ContinentCode: "EU", ContinentName: "Europe" },
    RW: { CountryCode: "RW", CountryName: "Rwanda", ContinentCode: "AF", ContinentName: "Africa" },
    SA: { CountryCode: "SA", CountryName: "Saudi Arabia", ContinentCode: "AS", ContinentName: "Asia" },
    SB: { CountryCode: "SB", CountryName: "Solomon Islands", ContinentCode: "OC", ContinentName: "Oceania" },
    SC: { CountryCode: "SC", CountryName: "Seychelles", ContinentCode: "AF", ContinentName: "Africa" },
    SD: { CountryCode: "SD", CountryName: "Sudan", ContinentCode: "AF", ContinentName: "Africa" },
    SE: { CountryCode: "SE", CountryName: "Sweden", ContinentCode: "EU", ContinentName: "Europe" },
    SG: { CountryCode: "SG", CountryName: "Singapore", ContinentCode: "AS", ContinentName: "Asia" },
    SH: { CountryCode: "SH", CountryName: "Saint Helena, Ascension and Tristan da Cunha", ContinentCode: "AF", ContinentName: "Africa" },
    SI: { CountryCode: "SI", CountryName: "Slovenia", ContinentCode: "EU", ContinentName: "Europe" },
    SJ: { CountryCode: "SJ", CountryName: "Svalbard and Jan Mayen", ContinentCode: "EU", ContinentName: "Europe" },
    SK: { CountryCode: "SK", CountryName: "Slovakia", ContinentCode: "EU", ContinentName: "Europe" },
    SL: { CountryCode: "SL", CountryName: "Sierra Leone", ContinentCode: "AF", ContinentName: "Africa" },
    SM: { CountryCode: "SM", CountryName: "San Marino", ContinentCode: "EU", ContinentName: "Europe" },
    SN: { CountryCode: "SN", CountryName: "Senegal", ContinentCode: "AF", ContinentName: "Africa" },
    SO: { CountryCode: "SO", CountryName: "Somalia", ContinentCode: "AF", ContinentName: "Africa" },
    SR: { CountryCode: "SR", CountryName: "Suriname", ContinentCode: "SA", ContinentName: "South America" },
    SS: { CountryCode: "SS", CountryName: "South Sudan", ContinentCode: "AF", ContinentName: "Africa" },
    ST: { CountryCode: "ST", CountryName: "Sao Tome and Principe", ContinentCode: "AF", ContinentName: "Africa" },
    SV: { CountryCode: "SV", CountryName: "El Salvador", ContinentCode: "NA", ContinentName: "North America" },
    SX: { CountryCode: "SX", CountryName: "Sint Maarten (Dutch part)", ContinentCode: "NA", ContinentName: "North America" },
    SY: { CountryCode: "SY", CountryName: "Syrian Arab Republic", ContinentCode: "AS", ContinentName: "Asia" },
    SZ: { CountryCode: "SZ", CountryName: "Eswatini", ContinentCode: "AF", ContinentName: "Africa" },
    TC: { CountryCode: "TC", CountryName: "Turks and Caicos Islands", ContinentCode: "NA", ContinentName: "North America" },
    TD: { CountryCode: "TD", CountryName: "Chad", ContinentCode: "AF", ContinentName: "Africa" },
    TF: { CountryCode: "TF", CountryName: "French Southern Territories", ContinentCode: "AN", ContinentName: "Antarctica" },
    TG: { CountryCode: "TG", CountryName: "Togo", ContinentCode: "AF", ContinentName: "Africa" },
    TH: { CountryCode: "TH", CountryName: "Thailand", ContinentCode: "AS", ContinentName: "Asia" },
    TJ: { CountryCode: "TJ", CountryName: "Tajikistan", ContinentCode: "AS", ContinentName: "Asia" },
    TK: { CountryCode: "TK", CountryName: "Tokelau", ContinentCode: "OC", ContinentName: "Oceania" },
    TL: { CountryCode: "TL", CountryName: "Timor-Leste", ContinentCode: "AS", ContinentName: "Asia" },
    TM: { CountryCode: "TM", CountryName: "Turkmenistan", ContinentCode: "AS", ContinentName: "Asia" },
    TN: { CountryCode: "TN", CountryName: "Tunisia", ContinentCode: "AF", ContinentName: "Africa" },
    TO: { CountryCode: "TO", CountryName: "Tonga", ContinentCode: "OC", ContinentName: "Oceania" },
    TR: { CountryCode: "TR", CountryName: "Turkey", ContinentCode: "AS", ContinentName: "Asia" },
    TT: { CountryCode: "TT", CountryName: "Trinidad and Tobago", ContinentCode: "NA", ContinentName: "North America" },
    TV: { CountryCode: "TV", CountryName: "Tuvalu", ContinentCode: "OC", ContinentName: "Oceania" },
    TW: { CountryCode: "TW", CountryName: "Taiwan, Province of China", ContinentCode: "AS", ContinentName: "Asia" },
    TZ: { CountryCode: "TZ", CountryName: "Tanzania, United Republic of", ContinentCode: "AF", ContinentName: "Africa" },
    UA: { CountryCode: "UA", CountryName: "Ukraine", ContinentCode: "EU", ContinentName: "Europe" },
    UG: { CountryCode: "UG", CountryName: "Uganda", ContinentCode: "AF", ContinentName: "Africa" },
    UM: { CountryCode: "UM", CountryName: "United States Minor Outlying Islands", ContinentCode: "OC", ContinentName: "Oceania" },
    US: { CountryCode: "US", CountryName: "United States of America", ContinentCode: "NA", ContinentName: "North America" },
    UY: { CountryCode: "UY", CountryName: "Uruguay", ContinentCode: "SA", ContinentName: "South America" },
    UZ: { CountryCode: "UZ", CountryName: "Uzbekistan", ContinentCode: "AS", ContinentName: "Asia" },
    VA: { CountryCode: "VA", CountryName: "Holy See", ContinentCode: "EU", ContinentName: "Europe" },
    VC: { CountryCode: "VC", CountryName: "Saint Vincent and the Grenadines", ContinentCode: "NA", ContinentName: "North America" },
    VE: { CountryCode: "VE", CountryName: "Venezuela (Bolivarian Republic of)", ContinentCode: "SA", ContinentName: "South America" },
    VG: { CountryCode: "VG", CountryName: "Virgin Islands (British)", ContinentCode: "NA", ContinentName: "North America" },
    VI: { CountryCode: "VI", CountryName: "Virgin Islands (U.S.)", ContinentCode: "NA", ContinentName: "North America" },
    VN: { CountryCode: "VN", CountryName: "Viet Nam", ContinentCode: "AS", ContinentName: "Asia" },
    VU: { CountryCode: "VU", CountryName: "Vanuatu", ContinentCode: "OC", ContinentName: "Oceania" },
    WF: { CountryCode: "WF", CountryName: "Wallis and Futuna", ContinentCode: "OC", ContinentName: "Oceania" },
    WS: { CountryCode: "WS", CountryName: "Samoa", ContinentCode: "OC", ContinentName: "Oceania" },
    YE: { CountryCode: "YE", CountryName: "Yemen", ContinentCode: "AS", ContinentName: "Asia" },
    YT: { CountryCode: "YT", CountryName: "Mayotte", ContinentCode: "AF", ContinentName: "Africa" },
    ZA: { CountryCode: "ZA", CountryName: "South Africa", ContinentCode: "AF", ContinentName: "Africa" },
    ZM: { CountryCode: "ZM", CountryName: "Zambia", ContinentCode: "AF", ContinentName: "Africa" },
    ZW: { CountryCode: "ZW", CountryName: "Zimbabwe", ContinentCode: "AF", ContinentName: "Africa" },
};

const ContinentMap = {
    EU: "Europe",
    AS: "Asia",
    NA: "North America",
    SA: "South America",
    AF: "Africa",
    AN: "Antarctica",
    OC: "Oceania",
};

const ALL_Continent = "AllContinent";
const ALL_Country = "AllCountry";

function GetFullCountry(countryCode) {
    if (!CountryMap[countryCode]){
        return ""
    }
    return countryCode + " | " + CountryMap[countryCode].CountryName;
}
function GetFullContinent(continentCode) {
    if (!ContinentMap[continentCode]){
        return ""
    }
    return continentCode + " | " + ContinentMap[continentCode];
}


const ContinentOption = [{value:ALL_Continent,text:ALL_Continent}];
const CountryOption = [{value:ALL_Country,text:"ALL_Location"}];
for (const key in CountryMap) {
    CountryOption.push({value:key,text:GetFullCountry(key)});
}
for (const key in ContinentMap) {
    ContinentOption.push({value:key,text:GetFullContinent(key)});
}

export { ALL_Continent, ALL_Country, CountryMap, ContinentMap, GetFullCountry, GetFullContinent,ContinentOption,CountryOption};
