<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new price record -->
                <!-- <b-overlay :show="add_price_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Add new price record"
                        @ok="addPriceRecord"
                    >
                        <b-form>
                            <b-form-group label="Continent" label-for="continentCode">
                                <v-select
                                    id="continentCode"
                                    label="text"
                                    :reduce="(text) => text.value"
                                    v-model="newPrice.continentCode"
                                    :options="ContinentOption"
                                    @input="continentInput"
                                />
                            </b-form-group>
                            <b-form-group label="Location" label-for="countryCode">
                                <v-select
                                    id="countryCode"
                                    label="text"
                                    :reduce="(text) => text.value"
                                    v-model="newPrice.countryCode"
                                    :options="CountryOption"
                                    @input="countryInput"
                                />
                            </b-form-group>

                            <b-form-group label="Price $/GB" label-for="price">
                                <b-form-input id="pdnPerByte" placeholder="price $/GB" v-model="newPrice.pdnPerByte" />
                            </b-form-group>

                            <b-form-group label="UserId" label-for="userId">
                                <b-form-input id="userId" placeholder="user id" v-model="newPrice.userId" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay> -->
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Speed Test List">
                <div class="pr-3 pb-3 pl-3">
                    <b-row>
                        <b-col cols="4">
                            <v-select
                                id="continentCode"
                                label="text"
                                :reduce="(text) => text.value"
                                placeholder="continent"
                                v-model="queryCondition.continentCode"
                                :options="ContinentOption"
                            />
                        </b-col>
                        <b-col cols="2" class="text-left">
                            <b-button variant="secondary" @click="search"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            class="mt-3"
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'country_code'">
                                    {{ props.row["country_code"] === ALL_Country ? ALL_Country : GetFullCountry(props.row["country_code"]) }}
                                </span>

                                <span v-else-if="props.column.field === 'test_unixtime'">
                                    {{
                                        props.row[props.column.field] === 1
                                            ? "immediately"
                                            : moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss")
                                    }}
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <!-- <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Cancel</b-button> -->

                                    <b-button class="ml-2" variant="primary" @click="sendSpeedTestCmd(props.row.originalIndex)"
                                        >To Top Priority</b-button
                                    >
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <!-- <b-modal id="modal_row_view_update" @hide="handleHide" title="view/edit" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="id" label-for="id">
                                    <b-form-input id="id" placeholder="id" v-model="this.focus.id" disabled />
                                </b-form-group>

                                <b-form-group label="Continent" label-for="continentCode">
                                    <v-select
                                        id="continentCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newPrice.continentCode"
                                        :options="ContinentOption"
                                        @input="continentInput"
                                    />
                                </b-form-group>
                                <b-form-group label="Location" label-for="countryCode">
                                    <v-select
                                        id="countryCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newPrice.countryCode"
                                        :options="CountryOption"
                                        @input="countryInput"
                                    />
                                </b-form-group>

                                <b-form-group label="Price $/GB" label-for="price">
                                    <b-form-input id="pdnPerByte" placeholder="price $/GB" v-model="newPrice.pdnPerByte" />
                                </b-form-group>

                                <b-form-group label="UserId" label-for="userId">
                                    <b-form-input id="userId" placeholder="user id" v-model="newPrice.userId" disabled />
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deletePriceRecord">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updatePrice">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal> -->
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BProgress,
    VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import moment from "moment";

import {
    ALL_Continent,
    ALL_Country,
    CountryMap,
    ContinentMap,
    GetFullCountry,
    GetFullContinent,
    ContinentOption,
    CountryOption,
} from "../../location/location";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        BProgress,
        VBTooltip,
        VueGoodTable,
        vSelect,
    },

    methods: {
        GetFullCountry,
        GetFullContinent,
        continentInput(code) {
            if (code === null || code === "") {
                this.newPrice.continentCode = this.ALL_Continent;
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Continent) {
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (this.newPrice.countryCode === this.ALL_Country) {
                return;
            }
            let currentCountryCode = this.newPrice.countryCode;
            let countryInfo = CountryMap[currentCountryCode];
            let currentContinentCode = code;
            if (countryInfo.ContinentCode !== currentContinentCode) {
                this.newPrice.countryCode = this.ALL_Country;
            }
        },
        countryInput(code) {
            if (code === null || code === "") {
                this.newPrice.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Country) {
                return;
            }
            let countryCodeInput = code;
            let countryInfo = CountryMap[countryCodeInput];
            let continentCode = countryInfo.ContinentCode;
            this.newPrice.continentCode = continentCode;
        },

        clickAddPriceRecord() {
            this.newPrice = {
                continentCode: ALL_Continent,
                countryCode: ALL_Country,
                pdnPerByte: 0,
                userId: 0,
            };
        },
        clearQueryCondition() {
            this.queryCondition = {
                ip: null,
                userId: null,
                continentCode: null,
                countryCode: null,
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.newPrice.continentCode = this.focus.continent_code === this.ALL_Continent ? this.ALL_Continent : this.focus.continent_code;
            this.newPrice.countryCode = this.focus.country_code === this.ALL_Country ? this.ALL_Country : this.focus.country_code;
            this.newPrice.userId = this.focus.user_id;
            this.newPrice.pdnPerByte = (this.focus.pdn_per_byte / 1000).toFixed(3);
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async sendSpeedTestCmd(rowid) {
            // console.log("send speed test cmd", this.row_data[rowid].ip);
            let resp = await this.$api.speedTest.speedTestCmd([this.row_data[rowid].ip], this.$store.state.auth.my_web_token);
           
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("test cmd sent", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let continent = this.queryCondition.continentCode;
            let resp = await this.$api.speedTest.speedTestList(continent, this.limit, this.offset, this.$store.state.auth.my_web_token);
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.test_list;
            this.totalRows = resp.result.count;
        },
    },

    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            moment: moment,
            //for table
            columns: [
                {
                    label: "IP",
                    field: "ip",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Location",
                    field: "country_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Next Test Time(UTC)",
                    field: "test_unixtime",
                    tdClass: "text-center",
                    thClass: "text-center",
                },

                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_price_show_overlay: false,

            queryCondition: {
                ip: null,
                continentCode: "NA",
            },

            ALL_Continent: ALL_Continent,
            ALL_Country: ALL_Country,
            CountryMap: CountryMap,
            ContinentMap: ContinentMap,
            ContinentOption: ContinentOption,
            CountryOption: CountryOption,
        };
    },
};
</script>
